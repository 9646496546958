import axios from 'axios';
import {
    REACT_APP_API_COMPANY_APPLY, REACT_APP_API_COMPANY_CHANGE_PASSWORD, REACT_APP_API_COMPANY_CHANGE_PROFILE_PIC,
    REACT_APP_API_COMPANY_CREATE_FILE, REACT_APP_API_COMPANY_CREATE_PROFILE_PIC, REACT_APP_API_COMPANY_EVALUATIONS,
    REACT_APP_API_COMPANY_JOBS, REACT_APP_API_COMPANY_METAFILES, REACT_APP_API_COMPANY_MISSIONS,
    REACT_APP_API_COMPANY_NOTIFICATIONS, REACT_APP_API_COMPANY_PROFILE, REACT_APP_API_COMPANY_QUERY_PHONE_NUMBER,
    REACT_APP_API_COMPANY_RESET_PASSWORD, REACT_APP_API_COMPANY_TRANSACTIONS, REACT_APP_API_COMPANY_VERIFY_PHONE_NUMBER
} from "../utils/enums/apiUrls";
import {CreateOrUpdateRequest, GetRequest} from "../utils/helpers/apiHelpers";

export const getNotifications = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_NOTIFICATIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const markNotificationsRead = async () => {
    const apiUrl = `${REACT_APP_API_COMPANY_NOTIFICATIONS}/mark_read_user?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put');
};

export const getUserMetadataFiles = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_METAFILES}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getApplicationById = async (id) => {
    const apiUrl = `${REACT_APP_API_COMPANY_APPLY}${id}/employer?lang=fr`;
    console.log("run **"+apiUrl);
    const app = await GetRequest(apiUrl);
    console.log("run **"+apiUrl);
    console.log("app** "+app);
    return app;
};
export const createTransaction = async (transaction) => {
     const apiUrl = `${REACT_APP_API_COMPANY_TRANSACTIONS}?lang=fr`;
     return await CreateOrUpdateRequest(apiUrl, transaction, 'post');
};

export const cancelJob = async (id) => {
    const apiUrl = `${REACT_APP_API_COMPANY_JOBS}/${id}/cancel?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put');
};

export const changePassword = async (password) => {
    const apiUrl = `${REACT_APP_API_COMPANY_CHANGE_PASSWORD}?lang=fr`
    const token = localStorage.getItem('token');
    axios.defaults.headers.common = {'Authorization': `bearer ${token}`};
    const body = JSON.stringify(password);
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const resetPassword = async (email) => {
    const apiUrl = `${REACT_APP_API_COMPANY_RESET_PASSWORD}?lang=fr`
    const body = JSON.stringify(email);
    return await CreateOrUpdateRequest(apiUrl, body, 'post');
};

export const updateUser = async (user) => {
    const apiUrl = `${REACT_APP_API_COMPANY_PROFILE}?lang=fr`;
    const body = JSON.stringify(user);
    return await CreateOrUpdateRequest(apiUrl,body, 'put');
};

export const changeProfilePic = async (data, state, fileId) => {
    const apiUrl = state === 'new' ? REACT_APP_API_COMPANY_CREATE_PROFILE_PIC
        : `${REACT_APP_API_COMPANY_CHANGE_PROFILE_PIC}${fileId}?lang=fr&fileType=profilePic`;
    let body = new FormData();
    body.append("file", data);
    const type = state === 'new' ? 'post' : 'put';
    return await CreateOrUpdateRequest(apiUrl, body, type, 'init');
}

export const changeFile = async (data, state, validity, fileType, fileId = null, bucketKey = null) => {
    const apiUrl = state === 'new' ?
        `${REACT_APP_API_COMPANY_CREATE_FILE}?lang=fr&fileType=${fileType}&${validity}=${validity}`
        : `${REACT_APP_API_COMPANY_CREATE_FILE}/${bucketKey}?lang=fr&fileType=${fileType}&${validity}=${validity}`;
    let body = new FormData();
    body.append("file", data);
    const type = state === 'new' ? 'post' : 'put';
    return await CreateOrUpdateRequest(apiUrl, body, type, 'init');
};

export const queryVerifyPhoneNumber = async () => {
    const apiUrl = `${REACT_APP_API_COMPANY_QUERY_PHONE_NUMBER}?lang=fr`
    return await CreateOrUpdateRequest(apiUrl, {}, 'post');
};

export const checkVerifyPhoneNumber = async (pinCode) => {
    const apiUrl = `${REACT_APP_API_COMPANY_VERIFY_PHONE_NUMBER}?lang=fr&pin=${pinCode}`;
    return await GetRequest(apiUrl);
};

export const getApplicationsFromJob = async (key, {idJob, limit, page}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_JOBS}/${idJob}/application?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getCandidateByApplicationId = async (key, {idApplication}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_APPLY}${idApplication}/candidate?lang=fr`;
    return await GetRequest(apiUrl);
};

export const appreciateApplication = async (data) => {
    const apiUrl = `${REACT_APP_API_COMPANY_APPLY}${data.idApplication}/appreciate?lang=fr`;
    const body = data.state === "rejected" ? {'state': data.state, 'reason': data.reason}:
        {'state': data.state};
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const getMyJobs = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_MISSIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getEvaluations = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_COMPANY_EVALUATIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};








