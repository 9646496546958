import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AvailableLangs} from "../config";
import Loader from "../commons/components/Loading";
import {LastLocationProvider} from 'react-router-last-location';
import Header from "../commons/components/Layout/Header";
import Footer from "../commons/components/Layout/Footer";
import ProtectedRoute from "./ProtectedRoute";
import BlogArticleListPage from "../Pages/CommonModule/BlogArticleList/View";
import BlogDetail from "../Pages/CommonModule/BlogArticleDetail/View";
const Home = React.lazy(() => retry(() => import('../Pages/CommonModule/Home/View/HomePage')));
const Login = React.lazy(() => retry(() => import('../Pages/CommonModule/Login')));
const Page404 = React.lazy(() => retry(() => import('../Pages/CommonModule/NotFound')));
const ResetPassword = React.lazy(() => retry(() => import('../Pages/CommonModule/ResetPassword')));
const ChangePassword = React.lazy(() => retry(() => import('../Pages/CommonModule/ChangePassword')));
const Register = React.lazy(() => retry(() => import('../Pages/CommonModule/Register')));
const RegisterFromApplying = React.lazy(() => retry(() => import('../Pages/CommonModule/RegisterFromApplying')));

const Jobs = React.lazy(() => retry(() => import('../Pages/CommonModule/JobList/View/JobListPage')));
const Profile = React.lazy(() => retry(() => import('../Pages/CommonModule/Profile/View/profilePage')));
const JobDetail = React.lazy(() => retry(() => import('../Pages/CommonModule/JobDetail')));
const EvaluationPost = React.lazy(() => retry(() => import('../Pages/CommonModule/CreateEvaluation/CreateEvaluationPage')));
const EvaluationDetail = React.lazy(() => retry(() => import('../Pages/CommonModule/EvaluationDetail/View/EvaluationDetailPage')));
const CreateJobs = React.lazy(() => retry(() => import('../Pages/EmployerModule/CreateJob/View/CreateJob')));
const Help = React.lazy(() => retry(() => import('../Pages/CommonModule/Help/HelpPage')));
const Values = React.lazy(() => retry(() => import('../Pages/CommonModule/Values/ValuesPage')));
const Classement = React.lazy(() => retry(() => import('../Pages/CommonModule/Classement/ClassmentPage')));
const History = React.lazy(() => retry(() => import('../Pages/CommonModule/History/HistoryPage')));
const DataPrivacy = React.lazy(() => retry(() => import('../Pages/CommonModule/DataPrivacy/DataPrivacyPage')));
const Notification = React.lazy(() => retry(() => import('../Pages/CommonModule/NotificationList/View/NotificationListPage')));
const EmployerJobs = React.lazy(() => retry(() => import('../Pages/EmployerModule/JobManager/View/JobManagerPage')));
const EmployerApplicationPage = React.lazy(() => retry(() => import('../Pages/EmployerModule/CandidateList/View/CandidateListPage')));
const CandidatePage = React.lazy(() => retry(() => import('../Pages/EmployerModule/CandidateProfile/CandidateProfilePage')));
const UserEvaluation = React.lazy(() => retry(() => import('../Pages/CommonModule/EvaluationList/View/EvaluationListPage')));
const Contracts = React.lazy(() => retry(() => import('../Pages/EmployeeModule/contractList/View/contractListPage')));
const TransactionDetail = React.lazy(() => retry(() => import('../Pages/CommonModule/TransactionDetail/TransactionDetailPage')));
const Contact = React.lazy(() => retry(() => import('../Pages/CommonModule/ContactUs/ContactUsPage')));
const ApplicationList = React.lazy(() => retry(() => import('../Pages/EmployeeModule/ApplicationList/View')));
const EmployeeList = React.lazy(() => retry(() => import('../Pages/EmployerModule/EmployeeList/View/EmployeeListPage')));
const Payment = React.lazy(() => retry(() => import('../Pages/CommonModule/PaymentMethod/PaymentPage')));
const Agreement = React.lazy(() => retry(() => import('../Pages/CommonModule/Agreement/AgreementPage')));
const Bill =  React.lazy(() => retry(() => import('../Pages/PaymentModule/Bill/View/BillPage')));
const PaymentSucceed = React.lazy(() => retry(() => import('../Pages/PaymentModule/PaymentSucceed/PaymentSucceedPage')));
const PaymentFailed =  React.lazy(() => retry(() => import('../Pages/PaymentModule/PaymentFailed/PaymentFailedPage')));

const retry = (fn, retriesLeft = 3, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }
                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
};
// Url mapping
export const Urls = {
    home: {
        path: "/",
        component: Home,
        protected: false
    },
    notification: {
        path: "/notifications",
        component: Notification,
        protected: true,
        scopes: "all"
    },
    register: {
        path: '/register',
        component: Register,
        protected: false
    },
    registerWithCodeAmbassador: {
        path: '/register/:codeAffiliation',
        component: Register,
        protected: false
    },
    bill: {
        path: '/job/:idJob/bill',
        component: Bill,
        protected: true,
        scopes: "employer"
    },
    registerFromApplying: {
    path: '/registerfromapplication/:slug',
    component: RegisterFromApplying,
    protected: false  
    }
    ,
    profile: {
        path: '/me/profile',
        component: Profile,
        protected: true,
        scopes: "all"
    },
    changePassword: {
        path: "/change-password/:userType",
        component: ChangePassword,
        protected: false
    },
    resetPassword: {
        path: "/reset-password",
        component: ResetPassword,
        protected: false
    },
    login: {
        path: "/login",
        component: Login,
        protected: false
    },
    logout: {
        path: "/logout",
        component: <redirect to='/fr/login'/>,
        protected: true,
        scopes: "all"
    },
    jobs: {
        path: "/jobs",
        component: Jobs,
        protected: false
    },
    blog: {
        path: "/blog",
        component: BlogArticleListPage,
        protected: false
    },
    blogDetail:{
        path: "/blog/reference/:slug",
        component: BlogDetail,
        protected: false
    },
    employerApplications: {
        path: "/job/:idJob/applications",
        component: EmployerApplicationPage,
        protected: true,
        scopes: "employer"
    },
    employee: {
        path: "/job/:idJob/employees",
        component: EmployeeList,
        protected: true,
        scopes: "employer"
    },
    profileCandidate: {
        path: "/job/:idJob/application/:idApplication/candidate-profile",
        component: CandidatePage,
        protected: true,
        scopes: "employer"
    },
    applications: {
        path: "/applications",
        component: ApplicationList,
        protected: true,
        scopes: "employee"
    },
    Contracts: {
        path: "/contracts",
        component: Contracts,
        protected: true,
        scopes: "employee"
    },
    userEvaluation: {
        path: "/user-evaluation",
        component: UserEvaluation,
        protected: true,
        scopes: "all"
    },
    evaluationPost: {
        path: "/evaluation-post/:job/:evaluator/:typeEvaluator/:typeEvaluation",
        component: EvaluationPost,
        protected: false
    },
    evaluationDetail: {
        path: "/evaluation/:evaluated/detail",
        component: EvaluationDetail,
        protected: false
    },
    jobDetail: {
        path: "/job/:id",
        component: JobDetail,
        protected: false
    },
    jobDetailSlug: {
        path: "/job/title_job/:slug",
        component: JobDetail,
        protected: false
    },
    newJob: {
        path: "/new-job",
        component: CreateJobs,
        protected: true,
        scopes: "employer"
    },
    updateJob: {
        path: "/job/:idJob/update",
        component: CreateJobs,
        protected: true,
        scopes: "employer"
    },
    history:{
        path: "/history",
        component: History,
        protected: false
    },
    transactionDetail: {
        path: "/transaction-detail/:idTransaction",
        component: TransactionDetail,
        protected: true,
        scopes: "all"
    }
    ,
    help: {
        path: "/help",
        component: Help,
        protected: false
    },
    contact: {
        path: "/contact",
        component: Contact,
        protected: false
    },
    value: {
        path: "/values",
        component: Values,
        protected: false
    },
    classement: {
        path: "/classement",
        component: Classement,
        protected: false
    },
    agreement: {
        path: "/agreement",
        component: Agreement,
        protected: false
    },
    PaymentInfo: {
        path: "/payment",
        component: Payment,
        protected: false
    },
    paymentFailed: {
        path: "/payment-failed",
        component: PaymentFailed,
        protected: true,
        scopes: "employer"
    },
    dataPrivacy: {
        path: "/data-privacy",
        component: DataPrivacy,
        protected: false
    },
    jobManager: {
        path: "/my-jobs",
        component: EmployerJobs,
        protected: true,
        scopes: "employer"
    },
    paymentSuccess: {
        path: "/payment-success",
        component: PaymentSucceed,
        protected: true,
        scopes: "employer"
    }
};

const Routes = () => {
    // Keys of Urls object that are used for creating Routes
    let urlsKeys = [];
    for(let key in Urls){
        urlsKeys.push(key)
    }
    return (
        <>
            <Router>
                <Header/>
                <Suspense fallback={<Loader/>}>
                    <LastLocationProvider>
                        <Switch>
                            {urlsKeys.map((entry, entryIndex) => (
                                AvailableLangs.map((lang, langIndex) => (
                                    Urls[entry].protected ?
                                        <ProtectedRoute
                                            key={`${entryIndex} - ${langIndex}`}
                                            path={`/${lang}${Urls[entry].path}`}
                                            component={Urls[entry].component}
                                            scopes={Urls[entry].scopes}
                                        /> : <Route exact
                                                    key={`${entryIndex} - ${langIndex}`}
                                                    path={`/${lang}${Urls[entry].path}`}
                                                    component={Urls[entry].component}
                                        />
                                ))
                            ))};
                            <Route exact path="/" component={Home}/>
                            <Route component={Page404} />
                        </Switch>
                    </LastLocationProvider>
                </Suspense>
                <Footer/>
            </Router>
        </>
    )
};

export default Routes;
