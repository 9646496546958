import {CalendarOutlined, EyeOutlined, LikeOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import styles from "./BlogStats.module.css";
import {addLikeBlogArticle} from "../../../services/blogArticle";
import {message} from "antd";
import {useSelector} from "react-redux";
import {connectedSelector} from "../../../redux/AuthReducer";
import {useHistory} from "react-router";

const BlogStats = (props)=>{
    const {publicationDate, nbLikes, nbViews, id} = props;
    const [currentNbLikes, setCurrentNbLikes] = useState(nbLikes);
    const [addLikeState, setAddLikeState] = useState(false);
    const connected = useSelector(connectedSelector);
    const history = useHistory();

    const addLike = async() =>{
        if(connected){
            try{
                const result = await addLikeBlogArticle(id);
                if(result === true){
                    const likes = currentNbLikes + 1;
                    setCurrentNbLikes(likes);
                    setAddLikeState(true);
                }
            }catch (e){
                message.error(e.message, 2);
            }
        }else{
            history.push('/fr/login');
        }
    }

    return(
        <div className={`${styles.blogCardDetails} grid-12`} >
            <div className="col-4">
                <CalendarOutlined /> {publicationDate}
            </div>
            <div className="col-4">
                <EyeOutlined /> {nbViews} vues
            </div>
            <div className={`col-4 ${styles.like}`} onClick={addLike}>
                { addLikeState === false ?
                    <LikeOutlined  />
                    :  <LikeOutlined style={{color: "blue"}} />}  {currentNbLikes} J'aime
            </div>
        </div>
    )
};

export default BlogStats;