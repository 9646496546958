import {
    REACT_APP_API_COMPANY, REACT_APP_API_COMPANY_TRANSACTIONS, REACT_APP_API_PARTICULAR, REACT_APP_API_PARTICULAR_APPLICATIONS,
    REACT_APP_API_PARTICULAR_APPLY, REACT_APP_API_PARTICULAR_BESTJOBERS, REACT_APP_API_PARTICULAR_CHANGE_PASSWORD,
    REACT_APP_API_PARTICULAR_CHANGE_PROFILE_PIC, REACT_APP_API_PARTICULAR_CHANGE_ROLE, REACT_APP_API_PARTICULAR_CREATE_FILE,
    REACT_APP_API_PARTICULAR_CREATE_PROFILE_PIC, REACT_APP_API_PARTICULAR_EVALUATIONS, REACT_APP_API_PARTICULAR_EXPERIENCES,
    REACT_APP_API_PARTICULAR_JOBS, REACT_APP_API_PARTICULAR_JOBS_VALIDATED, REACT_APP_API_PARTICULAR_METAFILES,
    REACT_APP_API_PARTICULAR_MISSIONS, REACT_APP_API_PARTICULAR_NB_APPLICATION, REACT_APP_API_PARTICULAR_NOTIFICATIONS,
    REACT_APP_API_PARTICULAR_PROFILE, REACT_APP_API_PARTICULAR_QUERY_PHONE_NUMBER, REACT_APP_API_PARTICULAR_RESET_PASSWORD,
    REACT_APP_API_PARTICULAR_TRANSACTIONS, REACT_APP_API_PARTICULAR_VERIFY_PHONE_NUMBER
} from "../utils/enums/apiUrls";
import {CreateOrUpdateRequest, GetRequest} from "../utils/helpers/apiHelpers";
import axios from "axios";

export const getApplications = async (key, {filterKey, limit = 0, page = 10, level = 1}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLICATIONS}?${filterKey}&lang=fr&limit=${limit}&page=${page}&level=${level}`;
    return await GetRequest(apiUrl);
};

export const getExperiences = async (key, {filterKey, limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_EXPERIENCES}?${filterKey}&lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getUserMetadataFiles = async (limit, page) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_METAFILES}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getValidatedJob = async (key, data) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_JOBS_VALIDATED}/${data.id}/applications/validated`;
    return await GetRequest(apiUrl);
};

export const getNbApplicationsByJob = async (key, {params}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_NB_APPLICATION}/${params.id}/nbApplications`;
    return await GetRequest(apiUrl);
};

export const getNotifications = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_NOTIFICATIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const ableNotifications = async (id) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR}/notify/me?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {isNotified:true}, 'put','init')};

export const disableNotifications = async (id) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR}/notify/me?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {isNotified:false}, 'put','init')};

export const markNotificationsRead = async () => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_NOTIFICATIONS}/mark_read_user?lang=fr`;
    const result = await CreateOrUpdateRequest(apiUrl, {}, 'put', 'init');
    return result.data.message;
};
export const apply = async (body) => {
    const apiFileManagerUrl = `${REACT_APP_API_PARTICULAR}/me/application/withFile?lang=fr`;
    const token = localStorage.getItem('token');
    axios.defaults.headers.common = {'Authorization': `bearer ${token}`};


    let bodyFile = new FormData();
    // add job information 
    bodyFile.append("job", body.job);
    bodyFile.append("motivations", body.motivations);   
    bodyFile.append("portfolio.url", body["portfolio.url"]);
    // add file 
    for (const [fileName, file] of Object.entries(body.files)) {
        if(file){
         bodyFile.append(fileName, file);
        }
      }
     const response = await CreateOrUpdateRequest(apiFileManagerUrl, bodyFile, 'post');  
    return response;
};

export const createTransaction = async (body) => {
    const apiUrl = localStorage.state !== "entreprise" ?
        `${REACT_APP_API_PARTICULAR_TRANSACTIONS}?mode=auto&lang=fr` :
        `${REACT_APP_API_COMPANY_TRANSACTIONS}?mode=auto&lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, body, 'post');
};

export const createExperience = async (experience) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_EXPERIENCES}?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, experience, 'post');
};

export const createEvaluation = async (Evaluation) => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common = {'Authorization': `bearer ${token}`};
    const apiUrl = `${REACT_APP_API_PARTICULAR_EVALUATIONS}?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, Evaluation, 'post');
};

export const changeRole = async (role) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_CHANGE_ROLE}?lang=fr`;
    const body = JSON.stringify({"role": role});
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const cancelApplication = async (id) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLY}${id}/cancel?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put','init');
};

export const cancelJob = async (id) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_JOBS}/${id}/cancel?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put');
};

export const changePassword = async (password) => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common = {'Authorization': `bearer ${token}`};
    const body = JSON.stringify(password);
    const apiUrl = `${REACT_APP_API_PARTICULAR_CHANGE_PASSWORD}?lang=fr`
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const resetPassword = async (email) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_RESET_PASSWORD}?lang=fr`;
    const body = JSON.stringify(email);
    return await CreateOrUpdateRequest(apiUrl, body, 'post');
};

export const createUser = async (user) => {
    const apiUrl = user.state.includes("company") ? REACT_APP_API_COMPANY : `${REACT_APP_API_PARTICULAR}?lang=fr`;
    const body = JSON.stringify(user);
    return await CreateOrUpdateRequest(apiUrl, body, 'post');
};



export const createUserFromApplication = async (user) => {
    const apiApplyWithFileUrl = `${REACT_APP_API_PARTICULAR}/me/application/withoutLoging?lang=fr`;
    const body = JSON.stringify(user);
    let bodyFile = new FormData();
    bodyFile.append("email.value", user.email.value);
    bodyFile.append("slug", user.slug);
    bodyFile.append("motivations", user.motivations);
    // add file 
    for (const [fileName, file] of Object.entries(user.files)) {
        if(file){
         bodyFile.append(fileName, file);
        }
      }
     await CreateOrUpdateRequest(`${REACT_APP_API_PARTICULAR}`, body, 'post');
     const response = await CreateOrUpdateRequest(apiApplyWithFileUrl, bodyFile, 'post');
     console.log(response)
     return response ;
};



export const updateUser = async (user) => {
    const body = JSON.stringify(user);
    const apiUrl = `${REACT_APP_API_PARTICULAR_PROFILE}?lang=fr`
    return await CreateOrUpdateRequest(apiUrl, body, 'put', 'init');
};
export const updateExperience = async (data) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_EXPERIENCES}/${data.id}?lang=fr`;
    const body = JSON.stringify(data.experience);
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const changeProfilePic = async (data, state, fileId) => {
    let body = new FormData();
    body.append("file", data);
    const apiUrl = state === 'new' ? REACT_APP_API_PARTICULAR_CREATE_PROFILE_PIC :
    `${REACT_APP_API_PARTICULAR_CHANGE_PROFILE_PIC}${fileId}?lang=fr&fileType=profilePic`;
    const type = state === 'new' ? 'post' : 'put';
    return await CreateOrUpdateRequest(apiUrl, body, type, 'init');
};

export const changeFile = async (data, state, validity, fileType, fileId = null, bucketKey = null) => {
    let body = new FormData();
    body.append("file", data);
    console.log("filaname in api "+data.name)
    const type = state === 'new' ? 'post' : 'put';
    const apiUrl = state === 'new' ?
        validity !== "" ? `${REACT_APP_API_PARTICULAR_CREATE_FILE}?lang=fr&fileType=${fileType}&validity=${validity}`
            : `${REACT_APP_API_PARTICULAR_CREATE_FILE}?lang=fr&fileType=${fileType}`
        : validity !== "" ? `${REACT_APP_API_PARTICULAR_CREATE_FILE}/${bucketKey}?lang=fr&fileType=${fileType}&validity=${validity}`
            : `${REACT_APP_API_PARTICULAR_CREATE_FILE}/${bucketKey}?lang=fr&fileType=${fileType}`;
    return fileType ? await CreateOrUpdateRequest(apiUrl,body, type, 'init') : null;
};

export const queryVerifyPhoneNumber = async () => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_QUERY_PHONE_NUMBER}?lang=fr`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'post', 'init');
};

export const checkVerifyPhoneNumber = async (pinCode) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_VERIFY_PHONE_NUMBER}?lang=fr&pin=${pinCode}`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put', 'init');
};

export const getApplicationsFromJob = async (key, {idJob, limit, page}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_JOBS}/${idJob}/application?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getCandidateByApplicationId = async (key, {idApplication}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLY}${idApplication}/candidate?lang=fr`;
    return await GetRequest(apiUrl);
};

export const getApplicationById = async (id) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLY}${id}/employer?lang=fr`;
    console.log("run **"+apiUrl);
    const app = await GetRequest(apiUrl);
    console.log("run **"+apiUrl);
    console.log(app);
    return app;
};

export const appreciateApplication = async (data) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLY}${data.idApplication}/appreciate?lang=fr`;
    const body = data.state === "rejected" ? {'state': data.state, 'reason': data.reason}:
        {'state': data.state};
    return await CreateOrUpdateRequest(apiUrl, body, 'put');
};

export const getMyJobs = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_MISSIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getMyTransactions = async (key, {idJob, limit, page}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_TRANSACTIONS}?requestType=full&job=${idJob}`;
    return await GetRequest(apiUrl);
};

export const getTransaction = async (key, {idTransaction}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_TRANSACTIONS}/${idTransaction}?requestType=full`;
    return await GetRequest(apiUrl);
};

export const getMyContracts = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_APPLY}?state=validated&lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getBestJobers = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_BESTJOBERS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getEvaluations = async (key, {limit = 0, page = 10}) => {
    const apiUrl = `${REACT_APP_API_PARTICULAR_EVALUATIONS}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};








