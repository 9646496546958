import {createSlice} from '@reduxjs/toolkit';

export const localeSlice = createSlice({
    name: 'locale',
    initialState: {
        // get language of browser. ex: en-US, browserLang = "en"
        //TODO remove the comment
        //lang: navigator.language.split("-")[0],
        lang: "fr"
    },
    reducers: {
        setFr: state => {
            state.lang = "fr";
        },
        setEn: state => {
            //TODO PUT "en"
            state.lang = "fr";
        },
    },
});

export const { setFr, setEn } = localeSlice.actions;

export const selectLang = state => state.locale.lang;

export const localeReducer = localeSlice.reducer;
