import {Button, Form, Input} from 'antd';
import React, { useState } from 'react';
import styles from "./Filter.module.css";
import {useHistory} from "react-router";

const Filter = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [searchKey, setSearchKey] = useState("");
    const onSearchChange = (e) => {
        setSearchKey(e.target.value);
    };

    const onFinish = () => {
        if (searchKey !== ""){
            history.push(`/fr/blog?searchKey=${searchKey}`)
        }else{
            history.push(`/fr/blog`);
        }
    };

    return (
        <div className={`${styles.content} item-center`}>
            <Form form={form} name="horizontal_login" layout={props.layoutMode} onFinish={onFinish}>
            <Form.Item
                label="Mots clés"
                name="keyword"
                wrapperCol={{ sm: 30 }}
              >
                <Input onChange={onSearchChange}
                    placeholder="Cv"
                />
            </Form.Item>
                <Form.Item>
                    <div className="grid-12 item-center">
                        <Button type="primary" htmlType="submit"
                                className={styles.darkButton}>Valider</Button>
                    </div>
                </Form.Item>
        </Form>
        </div>
    )
};

export default Filter;