import React from "react";
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {HomeOutlined, SendOutlined} from "@ant-design/icons";
import styles from "./BreadcumbZone.module.css";
import {useSelector} from "react-redux";
import {currentUserSelector} from "../../../redux/AuthReducer";

const BreadcumbZone = (props) => {
    const currentUserLocal = useSelector(currentUserSelector);

    return (
        <section className={`item-center ${styles.welcome}`}>
            <div className="container grid-12-small-4">
                <div className="col-8-small-4 left">
                    <h3>Bienvenue {currentUserLocal ?
                        currentUserLocal.surname.split(' ')[0] + ' ' + currentUserLocal.name.split(' ')[0] :
                        ' sur JOBAAS'} </h3>
                    <Breadcrumb separator=">" className={styles.navBrew}>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined/> <span>Accueil</span>
                            </Link>
                        </Breadcrumb.Item>
                        {props.links.map((value, index) => (
                            index === (props.links.length - 1) ?
                                <Breadcrumb.Item key={index}>
                                    <span>{value.title} </span>
                                </Breadcrumb.Item> : <Breadcrumb.Item key={index}>
                                    <Link to={value.url ? value.url : '/'}>
                                        <span>{value.title}</span>
                                    </Link>
                                </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                </div>
                <div className={`${styles.verticalCenter} ${styles.postMission} col-4-small-4 right`}>
                    {
                        currentUserLocal && currentUserLocal.role.includes('employer') ?
                            <>
                                <Link to="/fr/new-job">
                                    <button className={styles.buttonMission}>
                                        <SendOutlined/>Poster une annonce
                                    </button>
                                </Link></> : null
                    }
                </div>
            </div>
        </section>
    )
};

export default BreadcumbZone;
