import {
    REACT_APP_API_BLOG_ARTICLES
} from "../utils/enums/apiUrls";
import {CreateOrUpdateRequest, GetRequest} from "../utils/helpers/apiHelpers";

export const getBlogArticles = async(key, {limit, page}) => {
    const apiUrl = `${REACT_APP_API_BLOG_ARTICLES}?lang=fr&limit=${limit}&page=${page}`;
    return await GetRequest(apiUrl);
};

export const getBlogArticleBySlug = async (slug) =>{
    const apiUrl = `${REACT_APP_API_BLOG_ARTICLES}/${slug}?typeId=slug&lang=fr`;
    return await GetRequest(apiUrl);
}

export const searchBlogArticles = async (key, {searchKey, limit, page}) => {
    const apiUrl = `${REACT_APP_API_BLOG_ARTICLES}?lang=fr&typeFilter=raw&keyword=${searchKey}&limit=${limit}&page=${page}`;
    return searchKey !== null ? await  GetRequest(apiUrl) : null;
};

export const filterBlogArticles= async (key, {filterKey, limit, page}) => {
    const apiUrl = `${REACT_APP_API_BLOG_ARTICLES}?${filterKey}&limit=${limit}&page=${page}&lang=fr`;
    return filterKey !== "" ? await GetRequest(apiUrl) : null;
};

export const addLikeBlogArticle = async (idBlogArticle) =>{
    const apiUrl = `${REACT_APP_API_BLOG_ARTICLES}/${idBlogArticle}/like`;
    return await CreateOrUpdateRequest(apiUrl, {}, 'put');
};