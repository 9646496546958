import {AvailableLangs} from "../../config";
import Strings from "../../strings";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, setFr} from "./translate-reducer";

export const useTranslate = ()=> {
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    // get locale on url. ex: https//host.com/fr/HomePage, urlLang = "fr"
    const urlLang = window.location.pathname.split("/")[1];
    switch (urlLang) {
        case "fr":
            dispatch(setFr());
            break;
        case "en":
            dispatch(setFr());
            break;
        default:
            if (!AvailableLangs.includes(lang)) dispatch(setFr())
    }
    return (key) => {
        if(AvailableLangs.includes(lang)){
            return Strings[key][lang];
        }else{
            return "Not translated";
        }
    }
}
