import {configureStore} from '@reduxjs/toolkit';
import {localeReducer} from "../shared/translation";
import {authReducer} from "./AuthReducer";
import {paymentReducer} from "./PaymentReducer";

const Store = configureStore({
    reducer: {
        locale: localeReducer,
        auth: authReducer,
        payment: paymentReducer
    }
});

export default Store;
