import React from 'react';
import {Button, Result} from 'antd';
import {Link} from 'react-router-dom';

const ForbiddenPage = () => {
    document.title = '403 Page | Jobaas';

    return (
        <>
            <div className="index-item-center">
                <Result
                    status="403"
                    title="403"
                    subTitle="Vous n'êtes pas authorisé à accéder à cette page contacter le +237690637982"
                    extra={<Button type="primary"><Link to="/"> Retour à l'accueil</Link></Button>}
                />
            </div>
        </>
    );
};

export default ForbiddenPage;
