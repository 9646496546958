import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";

const EmployerMenuDropdown = ({setEmployerMenu}) => {
    return (
        <Menu>
            <Menu.Item key="7">
                <Link onClick={() => {
                    setEmployerMenu(false);
                }}
                      to={`/fr/my-jobs`}>Mes emplois</Link>
            </Menu.Item>
            <Menu.Item key="8">
                <Link onClick={() => {
                    setEmployerMenu(false);
                }}
                      to={`/fr/user-evaluation`}>Evaluations</Link>
            </Menu.Item>
        </Menu>
    );
};

export default EmployerMenuDropdown;