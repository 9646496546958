import {createSlice} from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        paymentState: null,
        job: null,
        idCharge: null,
        amount: null,
        position: "start"
    },
    reducers: {
        paymentSuccess: (state, action) => {
            state.paymentState = "validated";
            localStorage.setItem('paymentInfo', JSON.stringify(state))
        },
        paymentFailed: (state, action) => {
            state.paymentState = "failed";
            localStorage.setItem('paymentInfo', JSON.stringify(state))
        },
        paymentOut: (state, action) => {
            state.paymentState = null;
            state.idCharge = null;
            state.job = null;
            state.amount = null;
            state.position = "start";
            localStorage.setItem('paymentInfo', JSON.stringify(state))
        },
        paymentLoading: (state, action) => {
            state.paymentState = "loading";
            state.idCharge = action.payload.paymentInfos.idCharge;
            state.job = action.payload.paymentInfos.job;
            state.amount = action.payload.paymentInfos.amount;
            state.position = action.payload.paymentInfos.position;
            localStorage.setItem('paymentInfo', JSON.stringify(state))
        }
    },
});

export const paymentSuccessAction = () => async dispatch => {
    try {
        dispatch(paymentSuccess());
    } catch (e) {
        console.log(e);
        dispatch(paymentFailed());
    }
};

export const paymentLoadingAction = (paymentInfos) => async dispatch => {
    try {
        dispatch(paymentLoading({
            paymentInfos: paymentInfos
        }));
    } catch (e) {
        console.log(e.message);
        dispatch(paymentFailed());
    }
};

export const paymentOutAction = () => async dispatch => {
    try {
        dispatch(paymentOut());
    } catch (e) {
        dispatch(paymentFailed());
    }
};

export const jobPaymentSelector = state => state.payment.job;
export const paymentStateSelector = state => state.payment.paymentState;
export const idChargeSelector = state => state.payment.idCharge;
export const amountSelector = state => state.payment.amount;
const {paymentSuccess, paymentFailed, paymentLoading, paymentOut} = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
