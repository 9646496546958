import React from "react";
import jwtDecode from "jwt-decode";
import {logoutAction} from "../../../redux/AuthReducer";
import {useDispatch} from "react-redux";

const Layout = ({children}) => {
    const dispatch = useDispatch();
    if (localStorage.getItem("token")) {
        const jwt_Token_decoded = jwtDecode(localStorage.getItem("token"));
        if (jwt_Token_decoded.exp * 1000 < Date.now()) {
            dispatch(logoutAction());
        }
    }
    return (
        <>
            {children}
        </>
    );
};

export default Layout;
