import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";

const EmployerMenuMobileDropdown = ({setIsMenuOpened, isMenuOpened, setEmployerMenuMobile}) => {
    return (
        <Menu>
            <Menu.Item key="9">
                <Link onClick={() => {
                    setIsMenuOpened(!isMenuOpened);
                    setEmployerMenuMobile(false);
                }}
                      to={`/fr/my-jobs`}>Mes emplois</Link>
            </Menu.Item>
            <Menu.Item key="10">
                <Link onClick={() => {
                    setIsMenuOpened(!isMenuOpened);
                    setEmployerMenuMobile(false);
                }}
                      to={`/fr/user-evaluation`}>Evaluations</Link>
            </Menu.Item>
        </Menu>
    );
};

export default EmployerMenuMobileDropdown;