import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";

const EmployeeMenuDropdown = ({setEmployeeMenu}) => {
    return (
            <Menu>
                <Menu.Item key="1">
                    <Link onClick={() => {
                        setEmployeeMenu(false);
                    }}
                          to={`/fr/applications`}>Candidatures</Link>
                </Menu.Item>
                <Menu.Item key="2">
                    <Link onClick={() => setEmployeeMenu(false)}
                          to={`/fr/contracts`}>Contrats</Link>
                </Menu.Item>
                <Menu.Item key="3">
                    <Link onClick={() => setEmployeeMenu(false)}
                          to={`/fr/user-evaluation`}>Evaluations</Link>
                </Menu.Item>
            </Menu>
    )
};

export default EmployeeMenuDropdown;