import React from 'react';
import styles from './Footer.module.css';
import {Link} from "react-router-dom";
import facebookIcon from "../../../../assets/icons/facebook.svg";
import linkedinIcon from "../../../../assets/icons/linkedin.svg";
import twitterIcon from "../../../../assets/icons/twitter.svg";
import instagramIcon from "../../../../assets/icons/instagram.svg";
import logo from "../../../../assets/logos/logoHeader.svg";
import repair from "../../../../assets/icons/repair.svg";
import whatsappIcon from "../../../../assets/icons/WhatsApp.svg";

const Footer = (props) => {
    return (
        <footer>
            <div className={`${styles.support} item-center`}>
                <div className="container grid-12-small-4">
                    <p className="col-8-small-4 left" style={{color: "white"}}><img src={repair} alt="parameter"/>
                        Centre de support. <br/> Avez-vous des questions ?
                        N'hésitez pas à nous contacter
                    </p>
                    <p className="col-4-small-4 right">
                        <a href="https://wa.me/237690637982" style={{color: "white"}}>
                            <img src={whatsappIcon} alt="whatsapp"/>Contactez-nous.
                        </a>
                    </p>
                </div>
            </div>
            <div className={`${styles.footerLinks} item-center`}>
                <div className="container grid-12-small-4 has-gutter-l">
                    <div className="col-4-small-4">
                        <h3><img src={logo} alt="Jobaas logo"/></h3>
                        <div className="grid-12-small-4">
                            <div className="col-6-small-4">
                                <ul>
                                    <li><strong>A propos</strong></li>
                                    <li><Link to="/fr/history">Notre histoire</Link></li>
                                </ul>
                            </div>
                            <div className="col-6-small-4">
                                <ul>
                                    <li><strong>L'entreprise</strong></li>
                                    <li><Link to="/fr/values">Nos Valeurs</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-4-small-4">
                        <h3>Liens Utiles</h3>
                        <div>
                            <ul>
                                <li><Link to="/fr/data-privacy">Protection de données</Link></li>
                                <li><Link to="/fr/help">Aide</Link></li>
                                <li><Link to="/fr/payment">Paiement</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-4-small-4">
                        <h3>Suivez-nous</h3>
                        <div>
                            <ul>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://www.facebook.com/jobasaservice/"><img src={facebookIcon}
                                                                                           alt="facebook icon"/></a> &nbsp;
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://twitter.com/jobasaservice"><img src={twitterIcon}
                                                                                     alt="twitter icon"/></a> &nbsp;
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://www.linkedin.com/company/jobasaservice/"><img src={linkedinIcon}
                                                                                                   alt="linkedin icon"/></a> &nbsp;
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://www.instagram.com/jobasaservice/"><img src={instagramIcon}
                                                                                            alt="instagram icon"/></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.copy} item-center`}>
                <span>&copy; Jobaas, {new Date().getFullYear()} - Tous droits réservés.</span>
            </div>

        </footer>
    )
};

export default Footer;
