import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {paymentOutAction} from "./PaymentReducer";
import {
    REACT_APP_API_COMPANY_METAFILES, REACT_APP_API_COMPANY_PROFILE,
    REACT_APP_API_PARTICULAR_METAFILES, REACT_APP_API_PARTICULAR_PROFILE
} from "../utils/enums/apiUrls";

const initialUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const initialToken = localStorage.getItem('token');
const initialState = localStorage.getItem('state');
const initialConnected = !!initialUser;
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        currentUser: initialUser,
        connected: initialConnected,
        token: initialToken,
        state: initialState,
        userMetadataFiles: null,
        totalMetadataFiles: null,
        errorLogin: false,
        fileTypeList: null,
        userInfo: null
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.token = action.payload.token;
            localStorage.setItem('token', action.payload.token);
        },
        loginFailed: (state, action) => {
            state.errorLogin = true;
        },
        loadUser: (state, action) => {
            const user = localStorage.state !== "entreprise" ? {
                "id": action.payload.user.particular._id,
                "name": action.payload.user.particular.name,
                "surname": action.payload.user.particular.surname,
                "email": action.payload.user.particular.email.value,
                "initial": action.payload.user.particular.initial,
                "role": action.payload.user.particular.state
            } : {
                "id": action.payload.user.company._id,
                "name": action.payload.user.company.name,
                "surname": action.payload.user.company.surname,
                "email": action.payload.user.company.email.value,
                "initial": action.payload.user.company.initial,
                "role": ["employer", "company"]
            };
            state.userInfo = action.payload.user;
            localStorage.setItem('user', JSON.stringify(user));
            state.currentUser = user;
            state.connected = true;
        },
        logoutSuccess: (state) => {
            state.token = null;
            state.currentUser = null;
            state.connected = false;
            state.userMetadataFiles = null;
            state.errorLogin = false;
            state.errorMessage = "";
            localStorage.clear();
        },
        loadUserMetafiles: (state, action) => {
            state.userMetadataFiles = action.payload.userMetadataFiles;
            state.totalMetadataFiles = action.payload.totalMetadataFiles;
            state.fileTypeList = action.payload.fileTypeList;
        }
    },
});

export const loginAction = (token) => async dispatch => {
    try {
        dispatch(loginSuccess({
            token: token
        }));
        dispatch(getUser());
    } catch (e) {
        dispatch(loginFailed());
    }
};

export const logoutAction = () => async dispatch => {
    try {
        dispatch(logoutSuccess());
        dispatch(paymentOutAction());
    } catch (e) {
        dispatch(loginFailed());
    }
};

export const getUser = () => async dispatch => {
    try {
        if (localStorage.token) {
            let res = await axios.get(localStorage.state !== "entreprise" ?
                REACT_APP_API_PARTICULAR_PROFILE : REACT_APP_API_COMPANY_PROFILE);
            localStorage.setItem('idUser', localStorage.state !== "entreprise" ? res.data.data.particular._id : res.data.data.company._id);
            if (res.status === 200) {
                dispatch(loadUser(
                    {
                        user: res.data.data
                    }
                ));
                let result = await axios.get(localStorage.state !== "entreprise" ?
                    REACT_APP_API_PARTICULAR_METAFILES : REACT_APP_API_COMPANY_METAFILES);
                if (result.status === 200) {
                    dispatch(loadUserMetafiles(
                        {
                            userMetadataFiles: result.data.data.metadataFiles,
                            totalMetadataFiles: result.data.data.length,
                            fileTypeList: result.data.data.fileTypeList
                        }
                    ));
                }
            }
        }
    } catch (e) {
        dispatch(loginFailed());
    }
};

export const loadMetadatafiles = () => async (dispatch) => {
    try {
        let result = await axios.get(localStorage.state !== "entreprise" ?
            REACT_APP_API_PARTICULAR_METAFILES :
            REACT_APP_API_COMPANY_METAFILES);
        if (result.status === 200) {
            dispatch(loadUserMetafiles(
                {
                    userMetadataFiles: result.data.data.metadataFiles,
                    totalMetadataFiles: result.data.data.length,
                    fileTypeList: result.data.data.fileTypeList
                }
            ));
        }
    } catch (e) {
        dispatch(loginFailed());
    }
};

export const currentUserSelector = state => state.auth.currentUser;
export const userInfoSelector = state => state.auth.userInfo;
export const errorLoginSelector = state => state.auth.errorLogin;
export const userMetadataFileSelector = state => state.auth.userMetadataFiles;
export const totalMetadataFileSelector = state => state.auth.totalMetadataFiles;
export const fileTypeListSelector = state => state.auth.fileTypeList;
export const tokenSelector = state => state.auth.token;
export const connectedSelector = state => state.auth.connected;
const {loginSuccess, logoutSuccess, loadUser, loadUserMetafiles, loginFailed} = authSlice.actions;
export const authReducer = authSlice.reducer;
