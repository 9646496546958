import {useSelector} from "react-redux";
import {connectedSelector, currentUserSelector} from "../redux/AuthReducer";
import {Redirect, Route} from "react-router-dom";
import React from "react";
import ForbiddenPage from "../Pages/CommonModule/Forbbiden";

const ProtectedRoute = (props) => {
    const {path, component, scopes} = props;
    const connected = useSelector(connectedSelector);
    const currentUserLocal = useSelector(currentUserSelector);
    localStorage.setItem('previousPath', path);
    return connected ?
        (currentUserLocal.role.includes(scopes)  || scopes === "all") ?
            <Route exact path={path} component={component}/> :
           <ForbiddenPage /> : <Redirect to="/fr/login/"/>
};

export default ProtectedRoute;