import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";

const EmployeeMenuMobileDropdown = ({setIsMenuOpened, isMenuOpened, setEmployeeMenuMobile}) => {
    return (
        <Menu>
            <Menu.Item key="4">
                <Link onClick={() => {
                    setIsMenuOpened(!isMenuOpened);
                    setEmployeeMenuMobile(false);
                }}
                      to={`/fr/applications`}>Candidatures</Link>
            </Menu.Item>
            <Menu.Item key="5">
                <Link onClick={() => {
                    setIsMenuOpened(!isMenuOpened);
                    setEmployeeMenuMobile(false);
                }
                }
                      to={`/fr/contracts`}>Contrats</Link>
            </Menu.Item><Menu.Item key="6">
            <Link onClick={() => {
                setIsMenuOpened(!isMenuOpened);
                setEmployeeMenuMobile(false);
            }}
                  to={`/fr/user-evaluation`}>Evaluations</Link>
        </Menu.Item>
        </Menu>

    );
};

export default EmployeeMenuMobileDropdown;
