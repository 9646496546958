import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {NavLinks} from "../../../../config";
import Strings from "../../../../strings";
import {selectLang, useTranslate} from "../../../../shared/translation";
import styles from './Header.module.css';
import burgerIcon from "../../../../assets/icons/burger.svg";
import crossIcon from "../../../../assets/icons/cross.svg";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {connectedSelector, currentUserSelector, logoutAction} from "../../../../redux/AuthReducer";
import {useQuery, useQueryCache} from "react-query";
import {getNotifications as getParticularNotifications} from "../../../../services/particular";
import {getNotifications as getCompanyNotifications} from "../../../../services/company";
import logo from "../../../../assets/logos/logo.png";
import EmployerMenuDropdown from "./EmployerMenuDropdown";
import EmployeeMenuDropdown from "./EmployeeMenuDropdown";
import EmployerMenuMobileDropdown from "./EmployerMenuMobileDropdown";
import EmployeeMenuMobileDropdown from "./EmployeeMenuMobileDropdown";

const Header = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const connected = useSelector(connectedSelector);
    const lang = useSelector(selectLang);
    const currentUserLocal = useSelector(currentUserSelector);
    const t = useTranslate();
    const [employeeMenu, setEmployeeMenu] = useState(false);
    const [employerMenu, setEmployerMenu] = useState(false);
    const [profileMenu, setProfileMenu] = useState(false);
    const [employeeMenuMobile, setEmployeeMenuMobile] = useState(false);
    const [employerMenuMobile, setEmployerMenuMobile] = useState(false);
    const [profileMenuMobile, setProfileMenuMobile] = useState(false);
    const [visibleNotif, setVisibleNotif] = useState(false);
    const limit = 20;
    const page = 0;
    const queryCache = useQueryCache();
    const {data: listData, isLoading: listLoading} = useQuery(["listNotifications", {
        limit,
        page
    }], localStorage.getItem('state') !== "entreprise" ? getParticularNotifications : getCompanyNotifications, {
        retry: false
    });
    const dispatch = useDispatch();

    const logout = () => {
        // Get QueryCache from the context
        setProfileMenuMobile(false);
        queryCache.invalidateQueries('listNotifications');
        dispatch(logoutAction());
        setIsMenuOpened(false);
    };
    
    useEffect(() => {
        setProfileMenu(false);
    }, []);

    const profileMenuDropDown = (
        <Menu>
            <Menu.Item key="7">
                <Link to={`/${lang}/me/profile`} onClick={() => {
                    setProfileMenu(false);
                }}>
                    Mes informations
                </Link>
            </Menu.Item>
            <Menu.Item key="8">
                <li className={`${NavLinks.logout}`} onClick={logout}>
                    <Link to={`/${lang}/logout`}>
                        Se deconnecter
                    </Link>
                </li>
            </Menu.Item>
        </Menu>
    );

    const profileMenuDropDownMobile = (
        <Menu>
            <Menu.Item key="9">
                <Link to={`/${lang}/me/profile`} onClick={() => {
                    setIsMenuOpened(!isMenuOpened);
                    setProfileMenuMobile(false);
                }}>
                    Mes informations
                </Link>
            </Menu.Item>
            <Menu.Item key="10">
                <li className={`${NavLinks.logout}`} onClick={logout}>
                    <Link to={`/${lang}/logout`}>
                        Se deconnecter
                    </Link>
                </li>
            </Menu.Item>
            
        </Menu>
    );

    const handleEmployerMenuChangeMobile = flag => {
        setEmployerMenuMobile(flag);
    };

    const handleEmployeeMenuChangeMobile = flag => {
        setEmployeeMenuMobile(flag);
    };

    const handleProfileMenuChangeMobile = flag => {
        setProfileMenuMobile(flag);
    };

    const handleEmployerMenuChange = flag => {
        setEmployerMenu(flag);
    };

    const handleEmployeeMenuChange = flag => {
        setEmployeeMenu(flag);
    };

    const handleProfileMenuChange = flag => {
        setProfileMenu(flag);
    };

    //TODO REFACTORING DUPLICATES CODE
    return (
        <header className={styles.header}>
            <div className={`${styles.navbar} ${styles.itemCenter} ${styles.sticky}`}>
                <div className={`${styles.container} grid-12-small-4`}>
                    <div className="col-4-small-2">
                        <Link to="/">  <img   width="80" height="20" src={logo} alt="logo"/></Link>
                    </div>
                    <ul className={`col-8 ${styles.smallHidden} ${styles.menuDesktop} right`}>
                        <li className={`${NavLinks.home}`}><Link to="/">Accueil</Link></li>
                        <li className={`${NavLinks.jobs}`}><Link
                            to={`/fr/jobs`}>Emplois</Link></li>
                        {
                            connected ?
                            (
                                <>
                                    <li className={`${NavLinks.blog}`}><Link
                                        to={`/fr/blog`}>Blog</Link></li>
                                    {
                                        currentUserLocal && currentUserLocal.role.includes('employee') ?
                                            (
                                                <>
                                                    <Dropdown
                                                        overlay={EmployeeMenuDropdown({setEmployeeMenu})}
                                                        onVisibleChange={handleEmployeeMenuChange}
                                                        visible={employeeMenu}
                                                        trigger={['click']}
                                                    >
                                                        <li className={`${NavLinks.employee}`}>
                                                            <Link to="#">Menu Employé</Link>&nbsp;
                                                            <DownOutlined/>
                                                        </li>
                                                    </Dropdown>
                                                </>
                                            ) : null
                                    }
                                    {
                                        currentUserLocal && currentUserLocal.role.includes('employer') ?
                                            (
                                                <>
                                                    <Dropdown
                                                        overlay={EmployerMenuDropdown({setEmployerMenu})}
                                                        onVisibleChange={handleEmployerMenuChange}
                                                        visible={employerMenu}
                                                        trigger={['click']}
                                                    >
                                                        <li className={`${NavLinks.employer}`}>
                                                            <Link to="#">Menu Employeur</Link>&nbsp;
                                                            <DownOutlined/>
                                                        </li>
                                                    </Dropdown>
                                                </>
                                            ) : null
                                    }
                                    <li className={`${NavLinks.notifications}`}
                                        onClick={() => setVisibleNotif(!visibleNotif)}><Link
                                        to={`/fr/notifications`}>Notifications
                                        {
                                            !listLoading && listData && !visibleNotif ?
                                                <Badge count={listData.nbUnread} overflowCount={10}/> : null
                                        }
                                    </Link></li>
                                    <Dropdown
                                        overlay={profileMenuDropDown}
                                        onVisibleChange={handleProfileMenuChange}
                                        visible={profileMenu}
                                        trigger={['click']}
                                    >
                                        <li className={`${NavLinks.profile}`}>
                                            <Link to="#">Mon compte</Link>&nbsp;
                                            <DownOutlined/>
                                        </li>
                                    </Dropdown>
                                </>
                            ) :
                            (
                                <>
                                    <li className={`${NavLinks.blog}`}><Link
                                        to={`/fr/blog`}>Blog</Link></li>
                                    <li className={`${NavLinks.login}`}><Link
                                        to={`/fr/login`}>Se connecter</Link></li>
                                    <li className={`${NavLinks.register}`}><Link
                                        to={`/fr/register`}>S'inscrire</Link></li>
                                         <li className={`${NavLinks.Contact}`}><Link
                                             to={`/fr/contact`}>Contactez-nous</Link></li>
                                </>
                            )
                        }
                    </ul>
                </div>
                <div className={styles.burgerLogo}>
                    <button onClick={() => setIsMenuOpened(!isMenuOpened)}>
                        {!isMenuOpened ? <span><b>MENU</b></span> : null} &nbsp; &nbsp;<img
                        src={isMenuOpened ? crossIcon : burgerIcon} alt="menu icon"/>
                    </button>
                </div>
                <div className={isMenuOpened ? `${styles.menuMobile}` : `${styles.hidden}`}>
                    <ul>
                        <li className={`${NavLinks.home}`} onClick={() => {
                            setIsMenuOpened(!isMenuOpened)
                        }}><Link to="/">{t(Strings.home.key)}</Link></li>
                        <li className={`${NavLinks.jobs}`} onClick={() => {
                            setIsMenuOpened(!isMenuOpened)
                        }}><Link
                            to={`/${lang}/jobs`}>Emplois</Link></li>
                        <li className={`${NavLinks.blog}`} onClick={() => {
                            setIsMenuOpened(!isMenuOpened)
                        }}><Link
                            to={`/${lang}/blog`}>Blog</Link></li>
                        {connected ?
                            (
                                <>
                                    {
                                        currentUserLocal && currentUserLocal.role.includes('employee') ?
                                            (
                                                <>
                                                    <Dropdown
                                                        overlay={EmployeeMenuMobileDropdown({setIsMenuOpened, isMenuOpened, setEmployeeMenuMobile})}
                                                        onVisibleChange={handleEmployeeMenuChangeMobile}
                                                        visible={employeeMenuMobile}
                                                        trigger={['click']}
                                                    >
                                                        <li className={`${NavLinks.employee}`}>
                                                            <Link to="#">Menu Employé</Link>&nbsp;
                                                            <DownOutlined/>
                                                        </li>
                                                    </Dropdown>
                                                </>
                                            ) : null
                                    }
                                    {
                                        currentUserLocal && currentUserLocal.role.includes('employer') ?
                                            (
                                                <>
                                                    <Dropdown
                                                        overlay={EmployerMenuMobileDropdown({setIsMenuOpened, isMenuOpened, setEmployerMenuMobile})}
                                                        onVisibleChange={handleEmployerMenuChangeMobile}
                                                        visible={employerMenuMobile}
                                                        trigger={['click']}
                                                    >
                                                        <li className={`${NavLinks.employer}`}>
                                                            <Link to="#">Menu Employeur</Link>&nbsp;
                                                            <DownOutlined/>
                                                        </li>
                                                    </Dropdown>
                                                </>
                                            ) : null
                                    }
                                    <li className={`${NavLinks.notifications}`}
                                        onClick={() => {
                                            setVisibleNotif(!visibleNotif);
                                            setIsMenuOpened(!isMenuOpened);
                                        }}><Link
                                        to={`/${lang}/notifications`}>Notifications {
                                        !listLoading && listData && !visibleNotif ?
                                            <Badge count={visibleNotif ? listData.nbUnread : 0}
                                                   overflowCount={10}/> : null
                                    }
                                    </Link></li>
                                    <Dropdown
                                        overlay={profileMenuDropDownMobile}
                                        onVisibleChange={handleProfileMenuChangeMobile}
                                        visible={profileMenuMobile}
                                        trigger={['click']}
                                    >
                                        <li className={`${NavLinks.profile}`}>
                                            <Link to="#">Mon compte</Link>&nbsp;
                                            <DownOutlined/>
                                        </li>
                                    </Dropdown>
                                </>
                            ) :
                            (
                                <>
                                    <li className={`${NavLinks.login}`} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                                        <Link
                                        to={`/${lang}/login`}>{t(Strings.login.key)}</Link></li>
                                    <li className={`${NavLinks.register}`} onClick={() => {
                                        setIsMenuOpened(!isMenuOpened)
                                    }}><Link
                                        to={`/${lang}/register`}>{t(Strings.register.key)}</Link></li>
                                    <li className={`${NavLinks.contact}`} onClick={() => {
                                        setIsMenuOpened(!isMenuOpened)
                                    }}><Link
                                        to={`/${lang}/contact`}>Contactez-nous</Link></li>
                                </>
                            )
                        }
                    </ul>
                </div>
            </div>
            <br/><br/>
        </header>
    )
};

export default Header;
