const Strings = {
    home: {
        key: "home",
        fr: "Accueil",
        en: "Accueil"
    },
    notifications: {
        key: "notifications",
        fr: "Notifications",
        en: "Notifications"
    },

    evaluations: {
        key: "evaluations",
        fr: "Evaluations",
        en: "Evaluations"
    },
    applications: {
        key: "applications",
        fr: "Candidatures",
        en: "Applications",
    },
    jobs: {
        key: "jobs",
        fr: "Emplois",
        en: "Missions",
    }
    ,
    contracts: {
        key: "contracts",
        fr: "Contrats",
        en: "Contracts",
    },
    services: {
        key: "services",
        fr: "Services",
        en: "Services",
    },
    blog: {
        key: "blog",
        fr: "Blog",
        en: "Blog",
    },
    contact: {
        key: "contact",
        fr: "Contactez-nous",
        en: "Contact",
    },
    login: {
        key: "login",
        fr: "Se connecter",
        en: "Se connecter"
    },
    logout: {
        key: "logout",
        fr: "Se deconnecter",
        en: "Logout"
    },
    profile: {
        key: "profile",
        fr: "Mon compte",
        en: "My account"
    },
    userInfo: {
        key: "userInfo",
        fr: "Mes informations",
        en: "User Information"
    },
    register: {
        key: "register",
        fr: "S'inscrire",
        en: "S'inscrire"
    },
   
    mission: {
        key: "mission",
        fr: "mission",
        en: "mission"
    },
    headline: {
        key: "headline",
        fr: "Le Moyen le plus Simple de Trouver un Job.",
        en: "The Simplest Way to Find a Job"
    },
    jobManager: {
        key: "jobManager",
        fr: "Mes offres",
        en: "My jobs"
    },
    employerMenu: {
        key: "employerMenu",
        fr: "Menu Employeur",
        en: "Menu Employeur"
    },
    employeeMenu: {
        key: "employeeMenu",
        fr: "Menu Employé",
        en: "Employee Menu"
    },
    becomeEmployerMenu: {
        key: "becomeEmployerMenu",
        fr: "Publier une offre",
        en: "becomeEmployerMenu"
    },
    becomeEmployeeMenu: {
        key: "becomeEmployeeMenu",
        fr: "Postuler à une offre",
        en: "becomeEmployeeMenu"
    },
    classement: {
        key: "classement",
        fr: "Classement",
        en: "Classement"
    }
};

export default Strings;
