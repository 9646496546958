import axios from 'axios';

export const GetRequest = async(path) => {
    try {
        const res = await axios.get(path);
        return res.data.data;
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}

export const CreateOrUpdateRequest = async(path, body, type, mode = 'default') => {
    try {
        const res =  type === "post" ? await axios.post(path, body) : await axios.put(path, body);
        return mode === 'init' ? res : res.data.data;
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}

export const DeleteRequest = async(path) => {
    try {
        const res = await axios.delete(path);
        return res.data.data;
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}