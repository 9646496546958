const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const REACT_APP_API_APPLICATIONS = SERVER_URL + "api/v1/application";
export const REACT_APP_API_FILE_MANAGER = SERVER_URL + "api/v1/fileManager";
export const REACT_APP_AUTH_PARTICULAR = SERVER_URL + "api/v1/auth/Login/particular?lang=fr";
export const REACT_APP_AUTH_COMPANY = SERVER_URL + "api/v1/auth/Login/company?lang=fr";
export const REACT_APP_API_COMPANY_PROFILE = SERVER_URL + "api/v1/company/profile/me";
export const REACT_APP_API_COMPANY_NOTIFICATIONS = SERVER_URL + "api/v1/company/me/notification";
export const REACT_APP_API_COMPANY_MISSIONS = SERVER_URL + "api/v1/company/me/job";
export const REACT_APP_API_COMPANY_EVALUATIONS = SERVER_URL + "api/v1/company/me/evaluation";
export const REACT_APP_API_COMPANY_TRANSACTIONS = SERVER_URL + "api/v1/company/me/transaction";
export const REACT_APP_API_COMPANY_TRANSACTIONS_PAYPAL_CREATE_ORDER = SERVER_URL + "api/v1/company/me/transaction/payPalTransaction/createOrder";
export const REACT_APP_API_COMPANY_TRANSACTIONS_PAYPAL_CAPTURE_ORDER = SERVER_URL + "api/v1/company/me/transaction/payPalTransaction/captureOrder";
export const REACT_APP_API_COMPANY_JOBS = SERVER_URL + 'api/v1/company/me/job';
export const REACT_APP_API_COMPANY_QUERY_PHONE_NUMBER = SERVER_URL + "api/v1/company/me/send_sms_verification";
export const REACT_APP_API_COMPANY_VERIFY_PHONE_NUMBER = SERVER_URL + "api/v1/company/me/verify_sms_code";
export const REACT_APP_API_COMPANY_RESET_PASSWORD = SERVER_URL + 'api/v1/company/resetForgottenPassword/mail';
export const REACT_APP_API_COMPANY_CHANGE_PASSWORD = SERVER_URL + 'api/v1/company/changePassword?lang=fr';
export const REACT_APP_API_COMPANY_METAFILES = SERVER_URL + 'api/v1/company/me/metadatafiles';
export const REACT_APP_API_COMPANY_CREATE_FILE = SERVER_URL + 'api/v1/company/me/fileManager/file';
export const REACT_APP_API_COMPANY_CREATE_PROFILE_PIC = SERVER_URL + 'api/v1/company/me/fileManager/file?lang=fr&fileType=profilePic';
export const REACT_APP_API_COMPANY_CHANGE_PROFILE_PIC = SERVER_URL + 'api/v1/company/me/fileManager/file/';
export const REACT_APP_API_COMPANY_APPLY = SERVER_URL + 'api/v1/company/me/application/';
export const REACT_APP_API_MISSIONS = SERVER_URL + "api/v1/job";
export const REACT_APP_API_ESTIMATED_BILL = SERVER_URL + "api/v1/job/employerPayment/estimation";
export const REACT_APP_API_ADVANTAGES_PREMIUM = SERVER_URL + "api/v1/pack/advantages/premium";
export const REACT_APP_API_PACK = SERVER_URL + "api/v1/pack";
export const REACT_APP_COOLPAY_URL = "https://my-coolpay.com/api/" + process.env.REACT_APP_MYCOOLPAY_KEY + "/paylink";
export const REACT_APP_AUTH = SERVER_URL + "api/v1/prospect?lang=fr";
export const REACT_APP_API_STREETS = SERVER_URL + "api/v1/job/streets";
export const REACT_APP_API_PARTICULAR = SERVER_URL + "api/v1/particular";
export const REACT_APP_API_COMPANY = SERVER_URL + "api/v1/company";
export const REACT_APP_API_PARTICULAR_CHANGE_ROLE = SERVER_URL + 'api/v1/particular/me/change-role';
export const REACT_APP_API_PARTICULAR_PROFILE = SERVER_URL + "api/v1/particular/profile/me";
export const REACT_APP_API_BLOG_ARTICLES = SERVER_URL + "api/v1/blogArticle";
export const REACT_APP_API_PARTICULAR_APPLICATIONS = SERVER_URL + "api/v1/particular/me/application";
export const REACT_APP_API_PARTICULAR_BESTJOBERS = SERVER_URL + "api/v1/particular/me/bestJobers";
export const REACT_APP_API_PARTICULAR_NOTIFICATIONS = SERVER_URL + "api/v1/particular/me/notification";
export const REACT_APP_API_PARTICULAR_MISSIONS = SERVER_URL + "api/v1/particular/me/job";
export const REACT_APP_API_PARTICULAR_EVALUATIONS = SERVER_URL + "api/v1/particular/me/evaluation";
export const REACT_APP_API_PARTICULAR_TRANSACTIONS = SERVER_URL + "api/v1/particular/me/transaction";
export const REACT_APP_API_PARTICULAR_TRANSACTIONS_PAYPAL_CREATE_ORDER = SERVER_URL + "api/v1/particular/me/transaction/payPalTransaction/createOrder";
export const REACT_APP_API_PARTICULAR_TRANSACTIONS_PAYPAL_CAPTURE_ORDER = SERVER_URL + "api/v1/particular/me/transaction/payPalTransaction/captureOrder";
export const REACT_APP_API_PARTICULAR_EXPERIENCES = SERVER_URL + "api/v1/particular/me/experience";
export const REACT_APP_API_PARTICULAR_JOBS = SERVER_URL + 'api/v1/particular/me/job';
export const REACT_APP_API_PARTICULAR_NB_APPLICATION = SERVER_URL + 'api/v1/job';
export const REACT_APP_API_PARTICULAR_JOBS_VALIDATED = SERVER_URL + 'api/v1/job/user';
export const REACT_APP_API_PARTICULAR_QUERY_PHONE_NUMBER = SERVER_URL + "api/v1/particular/me/send_sms_verification";
export const REACT_APP_API_PARTICULAR_VERIFY_PHONE_NUMBER = SERVER_URL + 'api/v1/particular/me/phoneNumber/verification';
export const REACT_APP_API_PARTICULAR_RESET_PASSWORD = SERVER_URL + 'api/v1/particular/resetForgottenPassword/mail';
export const REACT_APP_API_PARTICULAR_CHANGE_PASSWORD = SERVER_URL + 'api/v1/particular/changePassword/';
export const REACT_APP_API_PARTICULAR_CREATE_PROFILE_PIC = SERVER_URL + 'api/v1/particular/me/fileS3?lang=fr&fileType=profilePic';
export const REACT_APP_API_PARTICULAR_CHANGE_PROFILE_PIC = SERVER_URL + 'api/v1/particular/me/fileS3/';
export const REACT_APP_API_PARTICULAR_METAFILES = SERVER_URL + 'api/v1/particular/me/metadatafiles';
export const REACT_APP_API_PARTICULAR_CREATE_FILE = SERVER_URL + 'api/v1/particular/me/fileS3';
export const REACT_APP_API_PARTICULAR_APPLY = SERVER_URL + 'api/v1/particular/me/application/';
export const REACT_APP_API_EVALUATIONS = SERVER_URL + "api/v1/evaluation";
export const REACT_APP_API_EVALUATIONS_PARTICULAR = SERVER_URL + "api/v1/particular";
export const REACT_APP_API_EXPERIENCES = SERVER_URL + "api/v1/experience";
export const REACT_APP_API_NOTIFICATIONS = SERVER_URL + "api/v1/notification";
export const REACT_APP_API_TAGS = SERVER_URL + "api/v1/tag";
export const REACT_APP_API_JOB_PAYMENT_STATE = SERVER_URL + "api/v1/job";