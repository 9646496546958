import {NavLinks} from "../../../../config";
import Layout from "../../../../commons/components/Layout";
import BreadcumbZone from "../../../../commons/components/BreadcumbZone";
import React, {useEffect, useState} from "react";
import styles from "./BlogArticleListPage.module.css";
import BlogCard from "../Components/BlogCard";
import Filter from "../Components/Filter";
import moment from "moment";
import {useHistory, useLocation} from "react-router";
import { useQuery} from "react-query";
import { getBlogArticles, searchBlogArticles} from "../../../../services/blogArticle";
import loading from "../../../../assets/icons/loading.gif";
import {Pagination} from "antd";

function useQueryParameter() {
    return new URLSearchParams(useLocation().search);
}

const BlogArticleListPage = () => {
    document.title = "Blog | Jobaas";
    let query = useQueryParameter();
    let searchKey = query.get("searchKey");
    let currentPage = query.get("page");
    const history = useHistory();
    const [page, setPage] = useState(currentPage === null ?
        0 : Number(currentPage)-1 <= 0 ? 0 : Number(currentPage)-1);
    const [layoutMode, setLayoutMode] = useState("inline");
    const limit = 4;
    let nbPages = 1;
    const {data: listData, isLoading: listLoading} = useQuery(["listBlogArticles", {limit, page}], getBlogArticles);
    const {data: searchData, isLoading: searchLoading} = useQuery(["searchBlogArticles", {searchKey, limit, page}], searchBlogArticles);
    if (searchKey !== null &&  !searchLoading) {
        nbPages = Math.ceil(searchData.length / limit)
    }

    if (searchKey === null && !listLoading) {
        nbPages = Math.ceil(listData.length / limit)
    }

    useEffect(() => {
        redefineSizeState();
        window.addEventListener("resize", redefineSizeState);
        window.scrollTo(0, 0);
        moment.locale('fr');
    }, []);

    const redefineSizeState = ()=>{
        if(window.innerWidth <= 720){
            setLayoutMode("vertical");
        }else{
            setLayoutMode("inline");
        }
    }

    const onPageChange = (page) => {
        if(searchKey==null){
            history.push('/fr/blog?page='+page)
        }else{
            history.push(`/fr/blog?page=${page}&searchKey=${searchKey}`)
        }
        window.scrollTo(0, 0);
        setPage(page - 1); // because pagination on backend start at 0
    };


    return (
        <Layout activeLink={NavLinks.blog}>
            <BreadcumbZone links={[{title: 'Blog'}]}/>
            <Filter layoutMode={layoutMode} />
            <div className={`${styles.content} item-center`}>
                {
                    searchKey !== null && searchLoading ?
                        <div style={{textAlign: "center"}}>
                            <img src={loading} alt="loading gif"/>
                        </div> :
                        <>
                            {
                                searchData !==undefined && searchData !== null  ?
                                    <div className="container grid-12 has-gutter-l">
                                        {
                                            searchData.blogArticles.length > 0 ?
                                                <>
                                                    {
                                                        searchData.blogArticles.map((blogArticle, key) => (
                                                            <div className="col-4">
                                                                <BlogCard
                                                                    key={key}
                                                                    id={blogArticle._id}
                                                                    title={blogArticle.title}
                                                                    slug={blogArticle.slug}
                                                                    overview={blogArticle.overview}
                                                                    profileType={blogArticle.profileType}
                                                                    publicationDate={moment(blogArticle.publicationDate).format('ll')}
                                                                    headImageUrl={blogArticle.headImageUrl}
                                                                    nbLikes={blogArticle.statistics.nbLikes}
                                                                    nbViews={blogArticle.statistics.nbViews}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <div className="col-12">
                                                    <h3 style={{textAlign: "center"}}>Aucun Article de blog pour ces mots clés</h3>
                                                </div>
                                        }
                                    </div> : null
                            }
                        </>
                }

                {
                    searchKey === null  ?
                        <>
                            {
                                listLoading ?
                                    <div style={{textAlign: "center"}}>
                                        <img src={loading} alt="loading gif"/>
                                    </div> :
                                    <div className="container grid-12 has-gutter-l">
                                        {
                                            listData.blogArticles.length > 0 ?
                                                <>
                                                    {
                                                        listData.blogArticles.map((blogArticle, key) => (
                                                            <div className="col-4">
                                                                <BlogCard
                                                                    key={key}
                                                                    id={blogArticle._id}
                                                                    title={blogArticle.title}
                                                                    slug={blogArticle.slug}
                                                                    overview={blogArticle.overview}
                                                                    profileType={blogArticle.profileType}
                                                                    publicationDate={moment(blogArticle.publicationDate).format('ll')}
                                                                    headImageUrl={blogArticle.headImageUrl}
                                                                    nbLikes={blogArticle.statistics.nbLikes}
                                                                    nbViews={blogArticle.statistics.nbViews}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <div className="col-12">
                                                    <h3 style={{textAlign: "center"}}>Aucun Article de blog pour
                                                        vous</h3>
                                                </div>
                                        }
                                    </div>
                            }
                        </> : null
                }
            </div>
            <div className="col-12 item-center">
                <Pagination
                    className={styles.pagination}
                    total={nbPages * 10}
                    current={page + 1} // because pagination on backend start at 0
                    onChange={onPageChange}
                    hideOnSinglePage={true}
                />
            </div><br/>
        </Layout>
    )
};

export default BlogArticleListPage;