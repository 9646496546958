import React from "react";
import styles from "./BlogCard.module.css";
import {Button, Tag} from "antd";
import {Link} from "react-router-dom";
import BlogStats from "../../../../../commons/components/BlogStats";

const BlogCard = (props) => {
   const  {title, headImageUrl, overview, nbLikes, nbViews, slug, publicationDate, id, profileType} = props;
   const altTitle = title.replace(" ", "_");

   const CustomTag = () => {
       return <>
           {
               profileType === "employer" ?
                   <Tag color="blue">article employeur</Tag> : <Tag color="green">article employé</Tag>
           }
       </>
   };

    return (
        <div className={`${styles.content}`}>
            <div className={`${styles.blogCard}`}>
                <div>
                    <img className={styles.blogCardImage} src={headImageUrl}   alt={altTitle}/>
                </div><br/>
                <div className={`${styles.blogCardTitle}`}>
                    <h1><b>{title}</b></h1>{CustomTag()}
                </div><br/>
                <div className={`${styles.blogCardDescription}`}>
                    <p>
                        {overview}
                    </p>
                </div>
                <BlogStats
                    id={id}
                    setToRefetch={props.setToRefetch}
                    publicationDate={publicationDate}
                    nbLikes={nbLikes}
                    nbViews={nbViews}
                />
                <br/>
                <div className="item-center">
                    <Link to={`/fr/blog/reference/${slug}`} >
                    <Button type="primary" htmlType="submit"
                            className={`${styles.darkButton}`}>
                        Voir Plus
                    </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default BlogCard