export const AvailableLangs = ["fr"];

export const NavLinks = {
    home: "home",
    notifications: "notifications",
    services: "services",
    candidates: "candidates",
    contact: "contact",
    login: "login",
    logout: "logout",
    applications: "applications",
    jobs: "offres",
    evaluations: "evaluations",
    register: "register",
    profile: "profile",
    employer: "employer",
    becomeEmployee:"Postuler à un job",
    becomeEmployer:"Publier un job",
    employee: "employee",
    classement: "classement",
    blog: "blog"
};
