import Layout from "../../../../commons/components/Layout";
import {NavLinks} from "../../../../config";
import BreadcumbZone from "../../../../commons/components/BreadcumbZone";
import styles from "../../BlogArticleDetail/View/BlogArticleDetail.module.css";
import React, {useEffect, useState} from "react";
import BlogStats from "../../../../commons/components/BlogStats";
import moment from "moment";
import {useParams} from "react-router";
import {getBlogArticleBySlug} from "../../../../services/blogArticle";
import Loader from "../../../../commons/components/Loading";

const BlogArticleDetail = ()=>{
    const {slug} = useParams();
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        moment.locale('fr');
        let mounted = true;
        getBlogArticleBySlug(slug).then(
            blogArticle => {
                if (mounted) {
                    setData(blogArticle);
                    document.title = `${blogArticle.title} | Jobaas`;
                    setIsLoading(false);
                }
            })
        return () => mounted = false;
    }, [setData, setIsLoading, slug]);

    return (
        <Layout activeLink={NavLinks.blog}>
            <BreadcumbZone links={[{title: 'Blog', url: '/fr/blog'}, {title: 'Blog Details'}]}/>
            {
                !isLoading ?
                    <div className={`${styles.content} item-center`}>
                        <div className="container grid-12 has-gutter-l">
                            <div className="col-12">
                                <img src={data.headImageUrl}
                                     alt="" width="400" height="400" className={styles.blogImage}/>
                                <div className={styles.blogCardDetails}>
                                    <BlogStats
                                        id={data._id}
                                        publicationDate={moment(data.publicationDate).format('ll')}
                                        nbLikes={data.statistics.nbLikes}
                                        nbViews={data.statistics.nbViews}
                                    />
                                </div><br/>
                                {data.text}
                            </div>
                        </div>
                    </div> : <Loader/>
            }
        </Layout>
    );
};

export default BlogArticleDetail;